<template>
  <div
    class="col-md-4 col-sm-12 ciasno-game-notifications csn-game-notification"
  >
    <div class="csn-game-notification-list-container">
      <slider
        ref="slider"
        :options="options"
        class="csn-game-notification-list-slider"
      >
        <slideritem
          v-for="{ id, icon, style, gameUrl, gameName, title } in items"
          :key="id"
          :style="style"
          class="csn-game-notification-slider"
        >
          <div>
            <component
              :is="getIcon(icon)"
              class="csn-game-notification-slider-icon"
            ></component>
            <div class="box">
              <div class="game-name">
                <Route :to="`${gamePlayPrefix}${gameUrl}`">
                  {{ gameName }}
                </Route>
              </div>

              <div class="description" v-html="title"></div>
            </div>
          </div>
        </slideritem>
        <div slot="loading">
          <div class="csn-game-notification-loader-container">
            <Loader />
          </div>
        </div>
      </slider>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'

import { GAME_NOTIFICATIONS, CASINO_FIVE, ResponseState } from '@/constants'
import { pipe, map, indexBy, generateUUID } from '@/helpers'
import { GameApi } from '@/api'

const options = {
  currentPage: 0,
  direction: 'vertical',
  slidesToScroll: 1,
  loop: true,
  loopedSlides: 1,
  thresholdDistance: 100,
  pagination: false,
}

//NOTE: there can be 3 icons: "cash", "new", "jackpots"

export default {
  name: GAME_NOTIFICATIONS,
  components: {
    slider,
    slideritem,
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
    'platin-cash-icon': () => import('@/components/svg/PlatinCashIcon.vue'), //cash
    'platin-new-icon': () => import('@/components/svg/PlatinNewGamesIcon.vue'), //new
    'platin-jackpots-icon': () =>
      import('@/components/svg/PlatinJackpotsIcon.vue'), //jackpots
    'five-cash-icon': () => import('@/components/svg/FiveCashIcon.vue'), //new
    'five-new-icon': () => import('@/components/svg/FiveNewGamesIcon.vue'), //new
    'five-jackpots-icon': () => import('@/components/svg/FiveJackpotsIcon.vue'), //jackpots
  },
  data: () => ({
    items: null,
  }),
  computed: {
    options: () => options,
    gamePlayPrefix: () => 'game/play/',
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
  },
  methods: {
    getIcon(iconName) {
      return `${this.isCasinoFive ? 'five' : 'platin'}-${iconName}-icon`
    },
  },
  async created() {
    try {
      const { data, state } = await GameApi.getGameNotifications()

      if (state === ResponseState.OK) {
        const { items } = data

        this.items = pipe(
          map((item) => ({ ...item, id: generateUUID() })),
          indexBy('id'),
        )(items || [])
      }
    } catch (e) {
      console.dir(e)
    }
  },
}
</script>
