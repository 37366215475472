var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4 col-sm-12 ciasno-game-notifications csn-game-notification"},[_c('div',{staticClass:"csn-game-notification-list-container"},[_c('slider',{ref:"slider",staticClass:"csn-game-notification-list-slider",attrs:{"options":_vm.options}},[_vm._l((_vm.items),function(ref){
var id = ref.id;
var icon = ref.icon;
var style = ref.style;
var gameUrl = ref.gameUrl;
var gameName = ref.gameName;
var title = ref.title;
return _c('slideritem',{key:id,staticClass:"csn-game-notification-slider",style:(style)},[_c('div',[_c(_vm.getIcon(icon),{tag:"component",staticClass:"csn-game-notification-slider-icon"}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"game-name"},[_c('Route',{attrs:{"to":("" + _vm.gamePlayPrefix + gameUrl)}},[_vm._v(" "+_vm._s(gameName)+" ")])],1),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(title)}})])],1)])}),_c('div',{attrs:{"slot":"loading"},slot:"loading"},[_c('div',{staticClass:"csn-game-notification-loader-container"},[_c('Loader')],1)])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }